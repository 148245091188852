import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import {
  getUnapprovedPaymentsAction,
  updateUnsettledPaymentsAction,
} from '../../Loan/store/loanActions';

interface LoanPayment {
  loanNumber: string | undefined;
  cashier: string;
  transactionDate: string;
  description: string;
  notes?: string;
  branch?: any;
  paymentType: string;
  _id: string;
  interestAmount: number;
  principal: number | undefined;
  OtherTransaction?: {
    Amount: number;
    Description: string;
  };
  paymentMethod?: string;
  customerId?: string;
  reducingInterest: number;
}

export default function LoanPaymentsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { unapprovedPayments }: any = useSelector<any>((state) => state?.loan);

  const [disabled, setDisabled] = useState(false);
  const getLoanPayments = async (): Promise<void> => {
    await getUnapprovedPaymentsAction(dispatch, {});
  };

  useEffect(() => {
    void getLoanPayments();
  }, []);

  const handleUpdate = async (loanPayment: LoanPayment, status: string) => {
    setDisabled(true);
    await updateUnsettledPaymentsAction(dispatch, {
      id: loanPayment._id,
      status,
    });
    void getLoanPayments();
    setDisabled(false);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: LoanPayment, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.branch?.name}</p>
      ),
    },
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      key: 'loanNumber',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.loanNumber}</p>
      ),
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.principal}</p>
      ),
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.interestAmount}</p>
      ),
    },
    {
      title: 'Reducing Interest',
      dataIndex: 'reducingInterest',
      key: 'reducingInterest',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.reducingInterest}</p>
      ),
    },
    {
      title: 'Accept',
      dataIndex: 'actions',
      width: 200,
      render: (_: any, record: LoanPayment, index: number) => (
        <p
          style={{
            color: 'green',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              void handleUpdate(record, 'cash_settlement');
            }
          }}
        >
          Accept
        </p>
      ),
    },
    {
      title: 'Reject',
      dataIndex: 'actions2',
      width: 200,
      render: (_: any, record: LoanPayment, index: number) => (
        <p
          style={{
            color: 'red',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              void handleUpdate(record, 'rejected');
            }
          }}
        >
          Reject
        </p>
      ),
    },
  ];

  return (
    <div className="users__table__page">
      {!(unapprovedPayments.length > 0) && <Spin spinning={false} fullscreen />}
      <div style={{ width: '100%' }}>
        <Table dataSource={unapprovedPayments} columns={columns} />
      </div>
    </div>
  );
}
