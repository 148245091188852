/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { type Customer, type Loan } from '../../Customer/store/customerTypes';
import { numberToWords } from 'amount-to-words';
import fmt from 'indian-number-format';

interface Props {
  loanDetails: Loan;
  customer: Customer;
  receiptNumber: string;
  receiptPrincipal: number;
  receiptInterest: number;
  paymentType: string;
  interestDays: number;
  tillDate: string;
  pendingPrincipal: number;
}

const PrintableContent: React.FC<Props> = ({
  loanDetails,
  customer,
  receiptNumber,
  receiptPrincipal,
  receiptInterest,
  paymentType,
  interestDays,
  tillDate,
  pendingPrincipal,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const orgName = localStorage.getItem('organizationName') ?? '';

  return (
    <div>
      <Button
        onClick={() => {
          handlePrint('');
        }}
        className="print__button"
      >
        <span>Print</span>
      </Button>
      <div ref={componentRef}>
        <div className="payment__receipt">
          <div className="loan__details">
            <h4 className="title">CASH RECEIPT VOUCHER</h4>
            <div className="loan__detail__row">
              <div className="left">
                <h3>{orgName}</h3>
                <p>{loanDetails.branch.name}</p>
                <p>{loanDetails.branch.address}</p>
                <p>
                  Phone: {loanDetails.branch.phoneNumber1},{' '}
                  {loanDetails.branch.phoneNumber2}
                </p>
              </div>
              <div className="right">
                <p>Customer Name: {customer.name}</p>
                <p>Loan No: {loanDetails.loanNumber}</p>
                <p>Date: {dayjs().format('DD-MM-YYYY')}</p>
                <p>Cash Receipt No: {receiptNumber}</p>
                {/* <p>Date: {dayjs().format('DD MMM YYYY')}</p> */}
              </div>
            </div>
          </div>
          <div className="receipt__table">
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                border: '1px solid black',
              }}
            >
              <tbody style={{ textAlign: 'center' }}>
                <tr>
                  <td style={{ border: '1px solid black' }}>Interest Paid</td>
                  <td style={{ border: '1px solid black' }}>
                    ₹ {fmt.format(receiptInterest)}
                  </td>
                </tr>

                {receiptPrincipal > 0 && (
                  <tr>
                    <td style={{ border: '1px solid black' }}>
                      Principal Paid
                    </td>
                    <td style={{ border: '1px solid black' }}>
                      ₹ {fmt.format(receiptPrincipal)}
                    </td>
                  </tr>
                )}

                <tr>
                  <td style={{ border: '1px solid black' }}>Total in words</td>
                  <td style={{ border: '1px solid black' }}>
                    {numberToWords(receiptInterest + receiptPrincipal ?? 0)}{' '}
                    Rupees Only
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }}>Paid To</td>
                  <td style={{ border: '1px solid black' }}>
                    {orgName} - {loanDetails.branch.name}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black' }}>Interest Type</td>
                  <td style={{ border: '1px solid black' }}>
                    {paymentType?.toUpperCase()} Interest
                  </td>
                </tr>
                {paymentType === 'full' && (
                  <>
                    <tr>
                      <td style={{ border: '1px solid black' }}>
                        Interest Days
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        {interestDays} Days
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black' }}>Till Date</td>
                      <td style={{ border: '1px solid black' }}>
                        {dayjs(tillDate).format('DD MMM YYYY')}
                      </td>
                    </tr>
                    {/* <tr>
                      <td style={{ border: '1px solid black' }}>
                        Remaining Principal
                      </td>
                      <td style={{ border: '1px solid black' }}>
                        ₹ {loanDetails?.remainingPrincipal}
                      </td>
                    </tr> */}
                  </>
                )}
                <tr>
                  <td style={{ border: '1px solid black' }}>
                    Balance Principal
                  </td>
                  <td style={{ border: '1px solid black' }}>
                    {fmt.format(pendingPrincipal)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="signature__section">
            <p>Paid by</p>
            <p>Verified by</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintableContent;
