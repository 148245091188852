/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useEffect } from 'react';
import { Spin, Table, Select, Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getOrgTransactionsAction } from '../store/expenseActions';
import { type TransactionResponse } from '../store/expenseTypes';
import fmt from 'indian-number-format';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default function OrganizationTransactionsIndex(): JSX.Element {
  const generatePDF = (transactionsArray: any) => {
    const groupedByType = transactionsArray.reduce((acc: any, item: any) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});

    // Define the desired order of types
    const typeOrder = [
      'Deposit',
      'Expense',
      'Investment',
      'Loan',
      'LoanPayment',
      'Pledge',
      'PledgePayment',
    ];

    // Create a new jsPDF document
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({ orientation: 'landscape' });
    doc.setFontSize(18);

    let firstPage = true;

    // Start Y coordinate for the first table
    const currentY = 20;

    // Iterate through the typeOrder to maintain the specific order of tables
    typeOrder.forEach((type) => {
      if (groupedByType[type]) {
        if (!firstPage) {
          doc.addPage();
        }
        firstPage = false;

        doc.text(type, 14, currentY);

        const startY = currentY + 10; // Adjust starting Y position for table after title

        const additionalHeaderTitle =
          type === 'Loan' || type === 'LoanPayment'
            ? 'loanNumber'
            : type === 'Pledge' || type === 'PledgePayment'
            ? 'pledgeNumber'
            : '';

        // Define headers for the table
        const headers = [
          [
            'S.No',
            'Description',
            'Amount',
            'Transaction Date',
            'Created Date',
            'Created Time',
            additionalHeaderTitle,
          ],
        ];

        // Remove the last header if no additional title is required
        if (!additionalHeaderTitle) {
          headers[0].pop();
        }

        // Map body data for the table
        const body = groupedByType[type].map((item: any, idx: number) => [
          idx + 1,
          item.description,
          item.amount,
          dayjs(item.transactionDate as string).format('DD-MM-YYYY'),
          dayjs(item.createdDate as string).format('DD-MM-YYYY'),
          dayjs(item.createdDate as string).format('hh:mm A'),
          additionalHeaderTitle
            ? item.transactionMappingData[additionalHeaderTitle]
            : '',
        ]);

        autoTable(doc, {
          startY,
          theme: 'grid',
          head: headers,
          body,
          columnStyles: {
            [headers[0].length - 1]: { cellWidth: 24 },
          },
          styles: {
            cellPadding: 3,
            fontSize: 6,
            valign: 'middle',
            halign: 'center',
          },
        });
      }
    });

    const branchLabel = branchOptions.filter((x) => x.value === branch)[0]
      .label;

    // Save the PDF
    doc.save(`Transactions-${branchLabel}-${startDate}-${endDate}.pdf`);
  };

  const { RangePicker } = DatePicker;

  const dispatch = useDispatch<AppDispatch>();

  const { orgTransactions }: any = useSelector<any>((state) => state?.expense);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (branch) {
      void getTransactions();
    }
  }, [branch]);

  const getTransactions = async (): Promise<void> => {
    setIsLoading(true);
    await getOrgTransactionsAction(dispatch, {
      branch,
      startDate,
      endDate,
    });
    setIsLoading(false);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: TransactionResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_: any, record: TransactionResponse, index: number) => (
        <p>{record.description}</p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: TransactionResponse, index: number) => (
        <p style={{ color: record.status === 'In' ? 'green' : 'red' }}>
          {fmt.format(record.amount)}
        </p>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: TransactionResponse, index: number) => (
        <p>{record.type}</p>
      ),
    },
    {
      title: 'Transaction Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: TransactionResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.transactionDate as string).format('DD-MM-YYYY')}</p>
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: TransactionResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.createdDate as string).format('DD-MM-YYYY')}</p>
      ),
    },
    {
      title: 'Created Time',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: TransactionResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.createdDate as string).format('hh:mm A')}</p>
      ),
    },
  ];

  const dateFormat = 'YYYY-MM-DD';

  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const onChangeDate = (values: any, formatString: [string, string]) => {
    setStartDate(formatString[0]);
    setEndDate(formatString[1]);
  };

  return (
    <div className="org_transactions__page">
      <Spin spinning={isLoading} fullscreen />

      <div style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
        <Select
          value={branch}
          onChange={(value: string): void => {
            setBranch(value);
          }}
          style={{ width: 311, height: 56 }}
          placeholder="Select branch"
          options={branchOptions}
          className="expense__input"
        />
        <RangePicker
          className="rangepicker"
          onChange={onChangeDate}
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
          format={dateFormat}
        />
        <Button onClick={getTransactions} className="search__button">
          <span>Search</span>
        </Button>
        {Boolean(orgTransactions.length) && (
          <Button
            onClick={() => {
              generatePDF(orgTransactions);
            }}
            className="search__button"
          >
            <span>Download PDF</span>
          </Button>
        )}
      </div>
      {Boolean(orgTransactions.length) && (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={orgTransactions}
            columns={columns}
            rowKey={(record: TransactionResponse) => record._id}
          />
        </div>
      )}
      {orgTransactions.length === 0 && (
        <div style={{ width: '100%' }}>
          <p>No transactions available for the branch</p>
        </div>
      )}
    </div>
  );
}
