import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '../../../redux/store';
// import {
//   getUnapprovedPaymentsAction,
//   updateUnsettledPaymentsAction,
// } from '../../Loan/store/loanActions';
import {
  getUnapprovedPledgeAction,
  updatePledgePaymentAction,
} from '../../Pledge/store/pledgeActions';
import { type PledgePayment } from '../../Pledge/store/pledgeTypes';

export default function PledgeApproval(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { unapprovedPledgePayments }: any = useSelector<RootState>(
    (rootState) => rootState.pledge
  );

  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUnapprovalList = async (): Promise<void> => {
    setIsLoading(true);

    await getUnapprovedPledgeAction(dispatch, {});

    setIsLoading(false);
  };

  useEffect(() => {
    void getUnapprovalList();
  }, []);

  const handleUpdate = async (pledgePayment: PledgePayment, status: string) => {
    setDisabled(true);
    await updatePledgePaymentAction(dispatch, {
      id: pledgePayment._id ?? '',
      status,
    });
    void getUnapprovalList();
    setDisabled(false);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: unknown, record: PledgePayment, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      width: 150,
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p>{record.branch?.name}</p>
      ),
    },
    {
      title: 'Pledge Number',
      dataIndex: 'pledgeNumber',
      key: 'pledgeNumber',
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p>{record.pledgeNumber}</p>
      ),
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p>{record.principal}</p>
      ),
    },
    {
      title: 'Interest Amount',
      dataIndex: 'interestAmount',
      key: 'interestAmount',
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p>{record.interestAmount}</p>
      ),
    },
    {
      title: 'Reducing Field',
      dataIndex: 'reducingField',
      key: 'reducingField',
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p>{record.reducingField}</p>
      ),
    },
    {
      title: 'Accept',
      dataIndex: 'actions',
      width: 100,
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p
          style={{
            color: 'green',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              void handleUpdate(record, 'cash_settlement');
            }
          }}
        >
          Accept
        </p>
      ),
    },
    {
      title: 'Reject',
      dataIndex: 'actions2',
      width: 100,
      render: (_: unknown, record: PledgePayment, index: number) => (
        <p
          style={{
            color: 'red',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              void handleUpdate(record, 'rejected');
            }
          }}
        >
          Reject
        </p>
      ),
    },
  ];

  return (
    <div className="users__table__page">
      {!(unapprovedPledgePayments.length > 0) && (
        <Spin spinning={isLoading} fullscreen />
      )}
      <div style={{ width: '100%' }}>
        <Table dataSource={unapprovedPledgePayments} columns={columns} />
      </div>
    </div>
  );
}
