/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Select } from 'antd';
import { type AppDispatch } from '../../../redux/store';
// import { getBranchAction } from '../../InterestScheme/store/interestSchemeActions';
import { getUnclosedPledgeAction } from '../../Pledge/store/pledgeActions';
import { resetUnclosedPledge } from '../../Pledge/store/pledgeReducer';

export const UnclosedPledgeIndex = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [branchOptions, setBranchOptions] = useState<SelectOption[]>(branches);
  const [value, setValue] = useState<string>('');

  const { unclosedPledge }: any = useSelector<any>((state) => state?.pledge);

  // const getFormDetails = async (): Promise<void> => {
  //   await getBranchAction(dispatch, {});
  // };

  const getUnclosedPledge = async (): Promise<void> => {
    setIsLoading(true);
    await getUnclosedPledgeAction(dispatch, { branch: value });
    setIsLoading(false);
  };

  // useEffect(() => {
  //   void getFormDetails();
  // }, []);

  useEffect(() => {
    if (value === '') {
      resetUnclosedPledge();
    }
    void getUnclosedPledge();
  }, [value]);

  // useEffect(() => {
  //   const branchOptionTemp: Array<{ label: string; value: string }> =
  //     branches.map((x: Branch) => {
  //       return {
  //         label: x.branch,
  //         value: x.branch,
  //       };
  //     });
  //   setBranchOptions(branchOptionTemp);
  // }, [branches]);

  return (
    <div>
      <Spin spinning={isLoading} fullscreen />
      <h4>Branch</h4>
      <Select
        style={{ width: 311, height: 56 }}
        placeholder="Select branch"
        onChange={(selectedvalue: string): void => {
          setValue(selectedvalue);
        }}
        options={branchOptions}
        // options={[{ value: 'krr', label: 'KRR' }]}
        className="deposit__select"
      />
      {value !== '' && unclosedPledge.length === 0 && (
        <p>No unclosed pledge found for this branch</p>
      )}
      {value.length > 0 && (
        <div
          style={{
            marginTop: 100,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 32,
              alignItems: 'center',
            }}
          >
            <p
              style={{
                color: '#DD1E2D',
              }}
            >
              Unclosed OB Loan
            </p>
            <p>Closed KGF Loan</p>
          </div>
          {unclosedPledge.map((x: any) => (
            <div
              key={x}
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 84,
                alignItems: 'center',
              }}
            >
              <a
                href={`/pledge?pledgeID=${x.pledgeNumber}`}
                style={{
                  color: '#DD1E2D',
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                {x.pledgeNumber}
                {x.loansClosed === 'partial' ? '*' : ''}
              </a>
              <p>
                {Object.entries(x.loanNumbers).map(
                  ([loanNumber, status], index) => (
                    <span
                      key={loanNumber}
                      style={{
                        textDecoration:
                          status === 'closed' ? 'line-through' : 'none',
                      }}
                    >
                      {loanNumber}
                      {index < Object.keys(x.loanNumbers).length - 1
                        ? ', '
                        : ''}
                    </span>
                  )
                )}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
