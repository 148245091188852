import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import SignInIndex from './modules/SignIn';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import ToastComponent from './components/ToastComponent';
import OverviewIndex from './modules/Overview';
import ProtectedLayout from './modules/Layout';
import CustomerIndex from './modules/Customer';
import TransactionsIndex from './modules/Transactions';
import { DepositFormIndex } from './modules/Deposit';
import { InvestmentFormIndex } from './modules/Investment';
import { InterestSchemeIndex } from './modules/InterestScheme';
import { UsersIndex } from './modules/Users';
import { PledgeAllIndex } from './modules/PledgeNew';
import { LoanAllIndex } from './modules/LoanNew';
import { LoanRecoveryIndex } from './modules/LoanNew/LoanRecovery';
import TermsIndex from './modules/Terms';

export default function App(): JSX.Element {
  return (
    <div>
      <ToastComponent />
      <Routes>
        <Route path="/sign-in" element={<SignInIndex />} />
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<OverviewIndex />} />

          <Route path="/customer" element={<CustomerIndex />} />

          <Route path="/pledge" element={<PledgeAllIndex />} />

          <Route path="/loan" element={<LoanAllIndex />} />

          <Route path="/transaction" element={<TransactionsIndex />} />

          <Route path="/deposit" element={<DepositFormIndex />} />

          <Route path="/investment" element={<InvestmentFormIndex />} />

          <Route path="/interest-scheme" element={<InterestSchemeIndex />} />

          <Route path="/users" element={<UsersIndex />} />

          <Route path="/recovery" element={<LoanRecoveryIndex />} />

          <Route path="/terms" element={<TermsIndex />} />
        </Route>
      </Routes>
    </div>
  );
}
