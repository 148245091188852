/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';

// Define the component for the printable content
const PrintableContent = () => {
  return (
    <div
      className="printable-content"
      style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}
    >
      <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
        விதிமுறைகள் மற்றும் நிபந்தனைகள்
      </h3>
      <ol style={{ lineHeight: '1.6', fontSize: '14px' }}>
        <li style={{ marginTop: 16 }}>
          நீங்கள் ஈடாக வைத்திருக்கும் பொருளுக்கு கூடுதல் கடன் மதிப்பீட்டை வைத்து
          உங்கள் வட்டி நிர்ணயிக்கப்படுகிறது.
        </li>
        <li style={{ marginTop: 16 }}>
          அடகு வைத்த தேதியிலிருந்து ஒரு வருடத்திற்குள் நகைகளை திருப்பவோ அல்லது
          மாற்றி புதுப்பித்து கொள்ளவோ தவறும் பட்சத்தில் அடுத்து வரும் நாட்களில்
          உங்கள் நகைகளை பகிரங்க ஏலத்தில் விடப்படும்.
        </li>
        <li style={{ marginTop: 16 }}>
          அடகு கடன் பெற்றவர்களுக்கு அனுப்பபடும் தபால்கள், இதர செலவுகள் அனைத்தும்
          தங்களின் கடன் கணக்கிலேயே சேரும்.
        </li>
        <li style={{ marginTop: 16 }}>
          நான் கடனாக வாங்கிய தொகை நேர்மையான தேவைக்கு மட்டுமே பயன்படுத்துவேன்
          அல்லாமல் நேரடியாகவோ, மறைமுகமாகவோ சட்டவிரோத செயல்களுக்கு இந்த பணம்
          பயன்படுத்தமாட்டேன் என் நிச்சயமாக உறுதி கூறுகிறேன்.
        </li>
        <li style={{ marginTop: 16 }}>
          நான் கடன் பெற்ற இந்த நாள் வரை என் மீது எந்த ஒரு திவாலா நோட்டீசும்,
          கடன் தீர்க்க வகையற்றவர் என்ற புகாரும், இது தொடர்பாக எந்த ஒரு நீதிமன்ற
          நடவடிக்கையும் இது நாள் வரை என் மீது இல்லை என்று உறுதி கூறுகிறேன்.
        </li>
        <li style={{ marginTop: 16 }}>
          நகைக்கடன் சம்பந்தமாக அதன் விதிமுறைகளைக் கூட்டவோ, மாற்றவோ, நீக்கவோ
          நிறுவனத்திற்கு முழு உரிமை உண்டு.
        </li>
        <li style={{ marginTop: 16 }}>
          அலுவலக நாட்களில் நகையை திருப்ப திங்கள் முதல் வெள்ளி வரை மதியம் 1.00
          மணிக்குள் பணம் செலுத்தி பிற்பகல் 3.00 மணிக்கு மேல் அடகு நகையை திரும்ப
          பெற்றுக் கொள்ள சம்மதிக்கிறேன். சனி, ஞாயிறு மற்றும் அரசு, உள்ளூர்
          விடுமுறை நாட்களிலும் நகை திருப்பித்தர இயலாது.
        </li>
        <li style={{ marginTop: 16 }}>
          தொகைக்கு நிகரான தங்க நகைகள் அடகு வைத்த தொகை வாங்குவதிலும் அல்லது
          எழுத்து வடிவில் ஈடு செய்யவும் நிறுவனத்திற்கு அதிகாரப்படுத்துகிறேன்.
        </li>
        <li style={{ marginTop: 16 }}>
          நான் அடமானமாக வைக்க தங்கள் நிறுவனத்திற்கு கொண்டு வந்து இருக்கும்
          நகைகள் அனைத்தும் என்னுடைய சொந்த நகைகள் தான் என்பதை உறுதியுடன்
          தெரிவித்துக் கொள்கிறேன்.
        </li>
        <li style={{ marginTop: 16 }}>
          தங்களது முகவரி மற்றும் அலைபேசி எதுவும் மாற்றம் இருந்தால் அதை உடனடியாக
          நிர்வாகத்திற்கு தெரியப்படுத்த வேண்டும் தவறும் பட்சத்தில் நிர்வாகம்
          பொறுப்பேற்காது.
        </li>
      </ol>
      <p style={{ marginTop: '20px', fontWeight: 'bold', textAlign: 'right' }}>
        பணம் பெறுவோரின் கையொப்பம்
      </p>
    </div>
  );
};

export default function TermsIndex() {
  // Reference to the component to be printed
  const componentRef = useRef(null);

  // Use react-to-print to handle printing
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="terms__page">
      {/* Render the printable content */}
      <div ref={componentRef}>
        <PrintableContent />
      </div>

      {/* Print button */}
      <Button
        className="print__button"
        onClick={handlePrint}
        style={{ marginTop: '20px' }}
      >
        <span>Print</span>
      </Button>
    </div>
  );
}
