import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest, getRequest, putRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const createNewLoan = createAsyncThunk(
  'loan/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.LOANS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchLoanNumbers = createAsyncThunk(
  'loan/search',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(`${API.LOANS}/loanNumber/${payload}`, {});
    
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchClosedLoanNumbers = createAsyncThunk(
  'loan/searchClosed',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(`${API.LOANS}/closed/loanNumber/${payload}`, {});
    
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLoanTypes = createAsyncThunk(
  'loan/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const metalRes = await getRequest(API.METALS, {});
      const interestTypeRes = await getRequest(API.LOAN_INTEREST, {});
     
      return {
        metals: metalRes.data,
        interest: interestTypeRes.data
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLoanByLoanNumber = createAsyncThunk(
  'loan/getLoan',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(`${API.LOANS}/loanNumber/${payload}`,{});
     
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnpledgedLoans = createAsyncThunk(
  'loans/unpledged',
  async (payload: {branch: string}, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.LOANS}/list/unpledged`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUndisbursedLoans = createAsyncThunk(
  'loans/undisbursed',
  async (payload: null, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.LOANS}/list/undisbursed`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUndisbursedLoan = createAsyncThunk(
  'loan/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(`${API.LOANS}/undisbursed/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnsettledPayments = createAsyncThunk(
  'payments/unsettled',
  async (payload: null, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.LOAN_PAYMENTS}/list/unsettled`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnapprovedPayments = createAsyncThunk(
  'payments/unapproved',
  async (payload: null, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.LOAN_PAYMENTS}/list/approvals`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUnsettledPayment = createAsyncThunk(
  'payments/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(`${API.LOAN_PAYMENTS}/${payload.id}`, {status: payload.status});
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnapprovedLoans = createAsyncThunk(
  'loans/unapproved',
  async (payload: null, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.LOANS}/list/unapproved`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRecoveryLoans = createAsyncThunk(
  'loans/recovery',
  async (payload: { branch: string, minDays: number | null, maxDays: number | null}, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.LOANS}/list/defaulters?branch=${payload.branch}&minDays=${payload.minDays}&maxDays=${payload.maxDays}`, {}
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUnapprovedLoan = createAsyncThunk(
  'loan/approval',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(`${API.LOANS}/approval/${payload.id}?status=${payload.status}`, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);