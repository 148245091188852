import React, { useState, useEffect } from 'react';
import TabComponent from '../../components/TabComponent';
import { UnclosedPledgeIndex } from './UnclosedPledge';
import PledgeIndex from '../Pledge';
import { Input, Select, Spin } from 'antd';
import OutlinedButton from '../../components/ButtonComponent/OutlinedButton';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { type AppDispatch } from '../../redux/store';
import { getPledgeByIdAction } from '../Pledge/store/pledgeActions';
import SearchSvg from '../../assets/icons/Search.svg';
import { PledgeRenewalIndex } from './PledgeRenewal';

import { resetPledgeState } from '../Pledge/store/pledgeReducer';
import PledgeApproval from './PledgeApproval';
import PledgePaymentList from './PledgePaymentList';

export const PledgeAllIndex = () => {
  const role: string | null = localStorage.getItem('role');
  const orgName: string | null = localStorage.getItem('organizationName');

  const dispatch = useDispatch<AppDispatch>();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabOptions = [
    {
      to: '',
      label: 'Pledge Form',
      index: 0,
    },
    {
      to: '',
      label: 'Unclosed ob loan',
      index: 1,
    },
    {
      to: '',
      label: 'Pledge Renewal List',
      index: 2,
    },
  ];

  if (
    orgName === 'Karur Gold Finance' &&
    role != null &&
    ['cashier', 'manager', 'superadmin'].includes(role)
  ) {
    tabOptions.push({ to: '', label: 'Pledge Payment Approvals', index: 3 });
  }

  const [searchParams] = useSearchParams();

  const [pledgeId, setPledgeId] = useState<string>(
    searchParams.get('pledgeID') ?? ''
  );

  useEffect(() => {
    void fetchPledgeById();
  }, [pledgeId]);

  useEffect(() => {
    setPledgeId(searchParams.get('pledgeID') ?? '');
  }, [searchParams]);

  const fetchPledgeById = async () => {
    if (pledgeId.length > 0) {
      await getPledgeByIdAction(dispatch, { pledgeNumber: pledgeId });
    } else {
      dispatch(resetPledgeState());
    }
  };

  const optionsMap: Record<string, string> = {
    pledgeNumber: 'Pledge ID',
    obNumber: 'OB Number',
  };

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>('pledgeNumber');

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(
      selectedType === 'pledgeNumber'
        ? e.target.value.toUpperCase()
        : e.target.value
    );
  };

  const searchPledge = async () => {
    setIsLoading(true);
    await getPledgeByIdAction(dispatch, { [selectedType]: searchText });
    setIsLoading(false);
  };

  const handleSearchType = (value: string): void => {
    setSelectedType(value);
  };

  const renderPledgeApproval = () => {
    if (role === 'cashier') {
      return <PledgePaymentList />;
    } else if (role === 'manager' || role === 'superadmin') {
      return <PledgeApproval />;
    }
  };

  return (
    <div className="pledge__all__page">
      <div className="title">
        <h1>Pledge</h1>
        <div className="right">
          <div className="search__container">
            <Select
              style={{
                width: 151,
                height: 48,
                backgroundColor: '#F8FAFC',
                border: '12px 0px 0px 12px',
              }}
              onChange={handleSearchType}
              className="dropdown__select"
              value={selectedType}
              options={[
                { label: 'Pledge ID', value: 'pledgeNumber' },
                { label: 'OB Number', value: 'obNumber' },
              ]}
            />

            <Input
              placeholder={`Enter ${optionsMap[selectedType]}`}
              value={searchText}
              onChange={onChangeText}
              className="search__input"
              prefix={<img src={SearchSvg} className="search__icon" />}
            />
          </div>

          <OutlinedButton
            label={
              isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: '#DD1E2D',
                      }}
                      spin
                    />
                  }
                />
              ) : (
                'Search'
              )
            }
            onClick={searchPledge}
          />
        </div>
      </div>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 2 && <PledgeRenewalIndex />}
        {selectedIndex === 1 && <UnclosedPledgeIndex />}
        {selectedIndex === 0 && <PledgeIndex />}
        {selectedIndex === 3 && renderPledgeApproval()}
      </div>
    </div>
  );
};
